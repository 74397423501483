import { Navbar } from "../../Components/Navbar/Navbar";
import { Footer } from "../../Components/Footer/Footer";
import { ContactUsBanner } from "./ContactUs/ContactUs";
import { Testimonials } from "./Testimonials/Testimonials";
import { OurPlans } from "./OurPlans/OurPlans";
import { Banner } from "./Banner/Banner";
import { Services } from "./Services/Services";
import { Vision } from "./Vision/Vision";

export const Home = () => {
  document.title = 'Home';
  
  return (
    <div>
      <Navbar />
      <Banner/>
      <main>
        <Vision/>
        <Services/>
        <OurPlans></OurPlans>
        <Testimonials></Testimonials>
        <ContactUsBanner></ContactUsBanner>
      </main>
      <Footer />
    </div>
  );
};
