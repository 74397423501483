import styles from "./Testimonials.module.css";
import ms from "mix-styles";

const testimonials = [
  {
    name: "Nissy Salisha",
    university: "Anhalt University, Germany",
    message: `It was a really an exquisite experience for me with Vision Vidhya, I must say. Without them it would be just a dream, can't thank them enough. Lastly, Hariprasad A, Thank you so much for being there all the time to get me through it. I owe you this.. Thank you.`,
  },
  {
    name: 'Kishore Babu B',
    university: 'University of Applied Sciences Jena',
    message: `I would like to take a moment to appreciate your efforts in fulfilling my dream to study in Germany”. You have done a great job throughout the journey. Right from the offer till getting the visa done you have helped and guided me. I would like to thank you for the services.`
  },
  {
    name: 'Naresh P',
    university: 'SRH Heidelberg,Germany.',
    message: `I would give a five-star rating to Vision Vidhya consultancy for their support for fulfilment of my dream of masters in Germany. I will strongly recommend vision Vidhya. My special thanks to Hariprasad.`
  },
  {
    name: 'Jessica K',
    university: 'Anhalt University, Germany,',
    message: `I am glad to share that it was an amazing experience with Vision Vidhya to fulfil my dream of masters in Germany. I strongly suggest Vision Vidhya for German aspirants.`
  }
];

const TestimonialCard = ({ name, message }) => {
  return (
    <div
      className={`${ms(
        styles["testimonial-card"],
        "text-start",
        "p-3",
        "rounded",
        "h-100"
      )}`}
    >
      <p className="h4">{name}</p>
      <div className="ps-3">
        <i className="bi bi-quote h1"></i>
        <p className="text-justify">{message}</p>
      </div>
    </div>
  );
};

export const Testimonials = () => {
  return (
    <section className={`container-fluid ${styles["testimonials"]}`}>
      <div className="row justify-content-center px-lg-5">
        <div className="col-12 my-3">
          <h2>Testimonials</h2>
        </div>
        {testimonials.map((x, i) => (
          <div
            className="col-12 com-md-6 col-lg-3 text-center mb-3 mb-lg-0"
            key={i}
          >
            <TestimonialCard name={x.name} message={x.message} />
          </div>
        ))}
      </div>
    </section>
  );
};
