import styles from "./OurPlans.module.css";
import ms from "mix-styles";
import { useNavigate } from "react-router";


const plans = [
  {
    name: "Standard Plan",
    messages: [
      "Complete Guidance",
      "SOP & LOR Provided",
      "5 Applications allowed",
      "Visa & Travel Guidance",
      "Flight Ticket Guidance",
    ],
    price: "50000",
  },
  {
    name: "Premium Plan",
    messages: [
      "Complete guidance",
      "All documents provided",
      "6 Applications allowed",
      "Application & Notary Service",
      "Visa & Travel Guidance",
      "Flight Ticket guidance",
    ],
    price: "80000",
  },
  {
    name: "Delux Plan",
    messages: [
      "Complete guidance",
      "All documents provided",
      "6 Applications allowed",
      "Application & Notary Service",
      "Visa & Travel Insurance Service",
      "Flight Ticket guidance",
    ],
    price: "100000",
  },
  {
    name: "Platinum Plan",
    messages: [
      "Complete guidance",
      "All documents provided",
      "7 Applications allowed",
      "Application & Notary Service",
      "Visa & Travel Insurance Service",
      "Flight Ticket Service",
      "Future Guidance",
    ],
    price: "150000",
  },
];

const PlanCard = ({ name, messages, price }) => {
  const navigate = useNavigate();
  return (
    <div className={`${ms(styles["plan-card"], "text-start", "px-3 pt-3")}`}>
      <h3 className="h4 text-uppercase">{name}</h3>
      <span className={`${styles["includes"]}`}>Includes:</span>
      <ul className="ps-3 py-3">
        {messages.map((x, i) => (
          <li key={i}>{x}</li>
        ))}
      </ul>
      <div className={`${styles["price"]} d-flex justify-content-between p-3 pointer`} onClick={() => {
        navigate('/services')
      }} >
        <span>₹{price}</span>{" "}
        <span>
          Go <i className="bi bi-arrow-right"></i>
        </span>
      </div>
    </div>
  );
};

export const OurPlans = () => {
  return (
    <section className={`container-fluid ${styles["plans"]} px-lg-5`}>
      <div className="row justify-content-center">
        <div className="col-12 my-5">
          <h2 className="text-white h2">Our Plans</h2>
        </div>
        <div className="row">
        {plans.map((x, i) => (
          <div
            className="col-12 col-md-6 col-lg-3 text-center mb-3 mb-lg-0"
            key={i}
          >
            <PlanCard name={x.name} messages={x.messages} price={x.price} />
          </div>
        ))}
        </div>
      </div>
    </section>
  );
};
