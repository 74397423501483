import ServicesStep from "../Services-Step/ServicesStep";
import { Link } from "react-router-dom";

export default function VisaTravel() {
  const services = [
    {
      step: "STEP1",
      head: "Prepare Documents",
      text: "We help you to prepare all the documents for your visa needs",
    },
    {
      step: "STEP2",
      head: "Check Documents ",
      text: "We will make sure the list of missing documents for your visa to avoid any confusions at the last moment.",
    },
    {
      step: "STEP3",
      head: "Attend Visa ",
      text: " We will prepare you and make sure that you are well informed about visa interview.",
    },
    {
      step: "STEP4",
      head: "Book your Trip",
      text: " We suggest you the best possible flights routes for your requirements and destination to reduce jet lag or travel burden based on your choice.",
    },
    {
      step: "STEP5",
      head: "Collect your visa ",
      text: "Get your approved visa for your desired date of travel and ready to start your journey.",
    },
  ];

  return (
    <div className="container">
      <section className="row justify-content-center">
        {services.map((x, i) => {
          return (
            <div className="col-10 py-2" key={i}>
              <ServicesStep p={x}></ServicesStep>
            </div>
          );
        })}
      </section>
      <div className="text-center py-5">
        <Link className="btn contact-btn px-4 py-3" to="/contact">
          Contact Us
        </Link>
      </div>
    </div>
  );
}
