import "./ProfileEvaluation.css";
import image from "../../../Assets/services/pe.webp";
import { Logo } from "../../../Components/Logo/Logo";
import { useState, useRef } from "react";
import { postData } from "../../../Services/api";

const formQuestions = [
  [
    "When are you planning to start your Masters?",
    ["this year", "next year", "in 2 years", "more than 2 years"],
  ],
  [
    "What is the Field of your Bachelors ?",
    [
      "Mechanical engineering",
      "Computer science",
      "Electronics",
      "Management",
      "Others",
    ],
  ],
  [
    "What is your grading system?",
    ["Precentage(100%)", "CGPA(Base10)", "CGPA(Base5)"],
  ],
  ["What is your expected or achieved equivalent grade?", [], "text"],

  [
    "How much work experience do you have?",
    ["0-1 years", "1-3 years", "more than 3 years"],
  ],
  [
    "Would you need an Education Loan to Fund Your Studies?",
    [
      "I do not know if I would need Education Loan yet",
      "Yes, I would need Education Loan",
      "No, I will come on my own money",
    ],
  ],

  [
    "Type of Bachelors Program",
    ["3 year bachelor program", "4 year bachelor program"],
  ],
  [
    "English Proficiency",
    [`Didn't write IELTS yet`, "Above 6.5 Bands", `Below 6.5 Bands`],
  ],
  [
    "German Proficiency",
    [`Didn't start learning German yet`, "Below B2", "Above B2"],
  ],
  [
    "Would you be open to study in German Taught programs?",
    [
      "Yes, I would like to study in German taught programs",
      "No, I do not want to study in German taught programs",
    ],
  ],
];

const questionObject = Object.fromEntries(formQuestions.map((x) => [x[0], ""]));

const EvaluationForm = () => {
  const [formData, setFormData] = useState(questionObject);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const formRef = useRef(null);
  const [isProgress, setIsProgress] = useState(false);

  const onSubmit = async () => {
    const qaData = Object.keys(formData).map((question) => ({
      question,
      ans: formData[question],
    }));
    const data = {
      qaData,
      firstName,
      lastName,
      email,
    };

    try {
      setIsProgress(true);
      const res = await postData(
        "https://api.visionvidhya.com/api/v1/profile-evaluation",
        data
      );
      formRef.current?.reset();
      setIsProgress(false);

      alert(
        res?.message ?? "Thank you for reaching us. We'll get back to you ASAP"
      );
    } catch (error) {
      setIsProgress(false);
      alert(
        error?.message ?? "Something went wrong please try after some time"
      );
    }
  };
  return (
    <form
      className="row evaluation-form"
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="col-12">
        {formQuestions.map((data, i) => {
          if (data[2] === "text") {
            return (
              <input
                id="percent"
                type="text"
                className="form-control form-control-lg mb-3"
                placeholder={data[0]}
                pattern="[0-9]{1,2}((\.[0-9]{1,2})?)%?"
                required
                maxLength={4}
                title={data[0]}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [data[0]]: e?.target.value,
                  });
                }}
              />
            );
          }
          return (
            <>
              <select
                className="form-select form-select-lg mb-3"
                required
                onChange={(e) => {
                  setFormData({ ...formData, [data[0]]: e?.target.value });
                }}
                key={i}
              >
                <option value="">{data[0]}</option>
                {data[1].map((option, k) => {
                  return (
                    <option value={option} key={k + 20}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </>
          );
        })}

        <select
          className="form-select form-select-lg mb-3"
          required
          onChange={(e) => {
            setFormData({
              ...formData,
              "Which plan you would like to choose?": e?.target.value,
            });
          }}
        >
          <option value="">Which plan you would like to choose?</option>

          {["Standard", "Premium", "Delux", "Platinum"].map((option, i) => {
            return (
              <option value={option} key={i + 20}>
                {option}
              </option>
            );
          })}
        </select>
        <input
          type="text"
          className="form-control form-control-lg mb-3"
          aria-label="First Name"
          name="First Name"
          required
          placeholder="First Name"
          onChange={(e) => {
            setFirstName(e?.target?.value);
          }}
        />

        <input
          type="text"
          className="form-control form-control-lg mb-3"
          aria-label="Last Name"
          name="Last Name"
          required
          placeholder="Last Name"
          onChange={(e) => {
            setLastName(e?.target?.value);
          }}
        />

        <input
          type="email"
          className="form-control form-control-lg mb-3"
          name="email_address"
          aria-label="Email Address"
          placeholder="Email Address"
          required
          onChange={(e) => {
            setEmail(e?.target?.value);
          }}
        />

        <div className="text-center">
          <button
            data-element="submit"
            className="btn app-primary btn-lg"
            disabled={isProgress}
          >
            <div className="d-flex align-items-center">
              <span className={isProgress ? "pe-3" : ""}>
                Submit for Evaluation
              </span>{" "}
              {isProgress && (
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
    </form>
  );
};

export const ProfileEvaluation = () => {
  document.title = "Services | Profile Evaluation";

  return (
    <div className="container-fluid my-3">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className="row justify-content-evenly">
            <div className="col-12 col-lg-5 my-3">
              <p className="h3 mb-5">Please fill out with care!</p>
              <EvaluationForm />
            </div>
            <div className="col-12 col-lg-5 px-0 my-4 my-lg-0">
              <div className="test about-vv">
                <div className="position-relative">
                  <img src={image} alt="bg" className="w-100 pe-image" />
                  <div className="position-absolute logo-container">
                    <Logo />
                  </div>
                </div>
                <div>
                  <p className="px-4 pt-4 text-justify">
                    Since 2017, we have been able to help out hundreds of
                    students to make a better decision in selecting better
                    universities in Germany. All of this we do on the basis of
                    the data that we have gathered from the students who receive
                    admits every single year from our own{" "}
                    <span className="text-primary">Vision Vidhya</span>.
                  </p>
                  <p className="p-4">
                    Questions like: Can I study in Germany with X percent?
                    Should I opt for public or private universities? All of this
                    we have been able to answer with the help of this free tool.
                  </p>
                </div>
                <div className="px-4 py-3 feedbacksection">
                  <p className="h5">Moghan Lal</p>
                  <span>Student</span>
                  <blockquote>
                    <div className="d-flex mt-3">
                      <span className="px-3">
                        <i className="bi bi-quote h1"></i>
                      </span>
                      <p className="text-justify">
                        I am glad that I found this tool, thanks to Hari prasad.
                        After filling out this form he sent me a really nice
                        email with the options I can have based on my profile.
                        Looking forward to the next steps of my German dream 🙂
                      </p>
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
