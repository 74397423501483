import ServiceComp from "./Service-Component/ServiceComp";
import "./Services.css";
const servicedata = [
  { logo: "bi bi-person-lines-fill", text: "Profile Evaluation", path: '/services' },
  { logo: "bi bi-people-fill", text: "Application and Admission",  path: '/services/application-and-admission' },
  { logo: "bi bi-person-x-fill", text: "Blocked Account & Health Insurance",  path: '/services/blocked-account' },
  { logo: "bi bi-briefcase-fill", text: "Visa and Travel",  path: '/services/visa' },
];

export const Services = () => {
  return (
    <div className="container-fluid offer-container pb-5 px-lg-5">
      <h1 className="h2 py-5">Our Services</h1>
      <div className="row justify-content-center">
        {servicedata.map((x) => {
          return (
            <div className="col-11 col-md-6 col-lg-3 ">
              <ServiceComp s={x}></ServiceComp>
            </div>
          );
        })}
      </div>
    </div>
  );
};
