import { Link } from "react-router-dom";
import "./ServiceComp.css";

const ServiceComp = (props) => {
  return (
      <div className="service-container px-0 h-100">
        <div className="px-3 py-5 service-head bg-light">
          <div>
            <i class={props.s.logo} style={{ fontSize: "3rem" }}></i>
          </div>
          <div style={{ minHeight: "4rem" }}>{props.s.text}</div>
        </div>
        <Link
          to={props.s.path}
          className="row d-flex align-items-center text-decoration-none text-dark px-4 py-3"
        >
          <div className="col-6 d-flex justify-content-start fs-5 fw-normal">
            {" "}
            Learn More
          </div>
          <div className="col-6 d-flex justify-content-end ">
            {" "}
            <i class="bi bi-arrow-right"></i>
          </div>
        </Link>
      </div>
  );
};

export default ServiceComp;
