import { Link } from "react-router-dom";
import Styles from "./Banner.module.css";

export const Banner = () => {
  return (
    <>
      <header
        className={`${Styles["banner"]} container-fluid d-flex align-items-md-center`}
      >
        <div className={`text-white ps-md-5 ${Styles["text-container"]}`}>
          <span className={`${Styles['line']}`}></span>
          <h2 className={`${Styles["title"]}`}>Study in Germany</h2>
          <span className={`${Styles["sub-title"]}`}>
            One of the leading study destinations
          </span>
          <div className="mt-3">
            <Link to={'/contact'} className="btn app-primary btn-lg">Contact</Link>
          </div>
        </div>
      </header>
    </>
  );
};
