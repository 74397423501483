import ServicesStep from "../Services-Step/ServicesStep";
import { Link } from "react-router-dom";

const steps = [
  {
    step: "STEP1",
    head: "Choose a Banck",
    text: `We will provide you the list of banks suitable for your needs for blocked account. Which are
        approved by German consulate.
        `,
  },
  {
    step: "STEP2",
    head: "Fill Application",
    text: "We guide you entire online process to open a blocked account successfully.",
  },
  {
    step: "STEP3",
    head: "Transfer funds",
    text: `We guide you through the amount criteria based on your visa needs and help you to make a
    successful transfer of money and proof documents for visa.`,
  },
  {
    step: "STEP4",
    head: "Travel Insurance",
    text: `Every visa category need travel insurance to be submitted along with visa documents for
    approval. We guide you through it.`,
  },
  {
    step: "STEP5",
    head: "Get ready for visa",
    text: "This is the next step to be taken care to start your new journey. We are glad to be a part of it.",
  },
];
export default function BlockedAccountAndInsurance() {
  document.title = 'Services | Blocked Account and Insurance';

  return (
    <div className="container">
      <section className="row justify-content-center">
        {steps.map((x) => {
          return (
            <div className="col-10 py-2">
              <ServicesStep p={x}></ServicesStep>
            </div>
          );
        })}
      </section>
      <div className="text-center py-5">
        <Link className="btn contact-btn px-4 py-3" to="/contact">Contact Us</Link>
      </div>
    </div>
  );
}
