import  historyimg  from '../../../Assets/Aboutme/Group.webp';
import '../../AboutMe/Aboutme.css';
import './VVhistory.css'
function VVhistory() {
    return (
        <div className="container-fluid">
           <section className="container">
           <div className="row">
           <div className="col-12 col-md-8 py-5">
                <h1 className="h1 fw-bold py-3"> Starting Visionvidhya:</h1>
                <p className="small text-justify">
                    I was looking to spend my time more productively with my studies first.
                    I used to get quite a few number of messages on my Facebook asking how they can start their studies in Germany too and after a while,
                    it became a bit redundant to write the same things over and over again. This is when I decided to start thinking of giving guidance for
                    the application processes and lifestyle of Germany.</p>
                <p className="small text-justify">
                    I didn’t plan this visionvidhya to have the kind of reception that it had but now
                    after having around 3 years of journey, I helped a lot of students to get into Germany successfully, that small fire turned out to be
                    successful and actually helped students to go through their entire application process and journey to Germany without getting ripped off
                    lakhs of rupees by other consultants.</p>
                <p className="small text-justify">
                    Along with running this website, I am also completed my Masters secured a full time job.
                    Parallel to that helping students/professionals with their aims and goals in Germany.
                    I am planning to keep sharing my experiences to show some light for the upcoming students aspiring for Germany.
                </p>
            </div>
            <div className="col-4 my-auto d-none d-md-block">
                <img className="w-100 " src={historyimg} alt="/"></img>
            </div>
           </div>
           </section>
        </div>
    )
}

export default VVhistory;