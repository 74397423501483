import { Link } from "react-router-dom";
import { useResolvedPath, useMatch } from "react-router";
import "./ServicesCategoryList.css";

const categories = [
  { title: "Profile Evaluation", path: "/services" },
  { title: "Application and Admission", path: "application-and-admission" },
  { title: "Blocked Account & Health Insurance", path: "blocked-account" },
  { title: "Visa and Travel", path: "visa" },
];

const Category = ({ title, path }) => {
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link
      to={path}
      className={`col-2 mx-2 py-3 btn text-center serlist-bg w-auto ${match ? 'active' : ''}`}
    >
      {title} <i className="p-2 bi bi-chevron-right"></i>
    </Link>
  );
};
export default function ServicesCategoryList() {
  return (
    <div className="d-flex justify-content-lg-center justify-content-sm-start py-5 responsive-list">
      {categories.map((x, i) => (
        <Category {...x}  key={i}/>
      ))}
    </div>
  );
}
