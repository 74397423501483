export const Logo = () => {
  return (
    <picture>
      <source
        media="(max-width: 767px)"
        sizes="(max-width: 767px) 12rem, 767px"
        srcSet="
/assets/logo/logo_ljmjma_c_scale,w_200.png 200w,
/assets/logo/logo_ljmjma_c_scale,w_767.png 767w"
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        sizes="(max-width: 991px) 15rem, 694px"
        srcSet="
      /assets/logo/logo_ljmjma_c_scale,w_538.png 538w,
      /assets/logo/logo_ljmjma_c_scale,w_694.png 694w"
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        sizes="(max-width: 1200px) 15rem, 720px"
        srcSet="
      /assets/logo/logo_ljmjma_c_scale,w_596.png 596w,
      /assets/logo/logo_ljmjma_c_scale,w_720.png 720w"
      />
      <img
        sizes="(max-width: 3055px) 15rem, 1222px"
        srcSet="
      /assets/logo/logo_ljmjma_c_scale,w_480.png 480w,
      /assets/logo/logo_ljmjma_c_scale,w_1222.png 1222w"
        src="/assets/logo/logo_ljmjma_c_scale,w_1222.png"
        alt=""
      />
    </picture>
  );
};
