import ms from "mix-styles";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { Logo } from "../Logo/Logo";

const services = [
  {
    title: "Profile Evaluation",
    link: "/services",
  },
  {
    title: "Application and admission",
    link: "/services/application-and-admission",
  },
  {
    title: "Blocked Account & Health Insurance",
    link: "/services/blocked-account",
  },
  {
    title: "Visa and Travel",
    link: "/services/visa",
  },
].map((item, i) => {
  return (
    <li key={i}>
      <Link to={item?.link}>{item.title}</Link>
    </li>
  );
});

const packs = [
  "Standard Plan - ₹50000",
  "Premium Plan - ₹80000",
  "Deluxe Plan - ₹100000",
  "Platinum - ₹150000",
].map((item, i) => <li key={i}>{item}</li>);

const FooterSection = ({ title, list }) => {
  return (
    <div className={`${ms(styles["section"])}`}>
      <h2 className={`${ms(styles["section-title"])}`}>{title}</h2>
      <ul className="list-style-none text-start">{list}</ul>
    </div>
  );
};

export const Footer = () => {
  return (
    <footer className={`${ms(styles["footer"], "container-fluid", "py-5")}`}>
      <div className="row">
        <div className="col-12 col-lg-3 text-start mb-3">
          <Link to={"/"}>
            <Logo />
          </Link>
          <div className="row text-white align-items-start my-3">
            <div className="col-1"> <i className="bi bi-geo-alt-fill"></i></div>
            <div className="col-11">
              <address className="text-white mb-0">
                No, 203, Nethaji Appartment, Navodhya Balaji
                Rainbow Villas, Tadepalli - Guntur, Vijyawada - Guntur -522501
              </address>
            </div>
          </div>
        </div>
        <div
          className={`${ms(
            "col-12 col-md-6 col-lg-3 ps-3 ps-lg-0",
            styles["section"]
          )}`}
        >
          <FooterSection title="Services" list={services} />
        </div>
        <div className="col-12 col-md-6 col-lg-3 ps-3 ps-lg-0">
          <FooterSection title="Our Offers" list={packs} />
        </div>
        <div className="col-12 col-lg-3 ps-md-0">
          <section
            className={`${ms(
              styles["section"],
              styles["social-link-container"]
            )}`}
          >
            <h2 className={`${ms(styles["section-title"])}`}>Connect</h2>
            <div className={`${ms(styles["social-link-icon-container"])}`}>
              <a
                href="https://www.facebook.com/VisionVidhya-561073607593073"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a
                href="https://twitter.com/VisionVidhya"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/visionvidhya/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/visionvidhya"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
            <p className="pt-3">
              We bring the Years, global experience, and stamina to guide our
              students new and often disruptive realities.
            </p>
          </section>
        </div>
      </div>
    </footer>
  );
};
