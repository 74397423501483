import './About.css';
import '../../AboutMe/Aboutme.css'
import businessman from '../../../Assets/Aboutme/businessman.webp';
function About() {
    return (
        <div className=" info-container h-auto">
           <div className="container-fluid">
           <div className="container">
           <div className="row">
                <div className="col-12 col-md-6 my-auto">
                    <h1 className="fw-bold h1 py-3">About Me</h1>
                    <p className="small text-justify">
                        My name is Hariprasad Aleswaram and I have done my Masters in Germany.
                        Along with a full time job, I am a Guide and an Entrepreneur in the digital creative world.
                    </p>
                </div>
                <div className="col-6 d-none d-md-block ">
                    <img className="about-image" src={businessman} alt="/"></img>
                </div>

            </div>
           </div>
           </div>

        </div>
    )
}
export default About;