import "./ContactUs.css";
import conatcatUsImage from "../../Assets/contact-us/contactus.webp";
import { useState, useRef } from "react";
import { postData } from "../../Services/api";

const ContactUsForm = () => {
  document.title = "Contact US";
  const formRef = useRef(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const [isProgress, setIsProgress] = useState(false);

  return (
    <form
      className="row"
      ref={formRef}
      onSubmit={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        try {
          setIsProgress(true);
          const result = await postData(
            "https://api.visionvidhya.com/api/v1/contact-us",
            {
              email,
              firstName,
              lastName,
              subject,
              text: body,
            }
          );
          formRef.current?.reset();
          alert(
            result?.message ??
              "Thank you for reaching us. We'll get back to you ASAP"
          );
          setIsProgress(false);
        } catch (error) {
          setIsProgress(false);
          alert(
            error?.message ?? "Something went wrong please try after some time"
          );
          console.log(error);
        }
      }}
    >
      <div className="col-12 col-md-6 my-2">
        <input
          className="form-control form-control-lg"
          type="text"
          placeholder="Firstname"
          aria-label="Firstname firstname"
          required
          onChange={(e) => {
            setFirstName(e?.target.value);
          }}
        />
      </div>
      <div className="col-12 col-md-6 my-2">
        <input
          className="form-control form-control-lg"
          type="text"
          placeholder="Lastname"
          aria-label="Lastname"
          onChange={(e) => {
            setLastName(e?.target.value);
          }}
        />
      </div>
      <div className="col-12 my-2">
        <input
          className="form-control form-control-lg"
          type="email"
          placeholder="Email address"
          aria-label="Email"
          required
          onChange={(e) => {
            setEmail(e?.target.value);
          }}
        />
      </div>
      <div className="col-12 my-2">
        <input
          className="form-control form-control-lg"
          type="text"
          placeholder="Subject"
          aria-label="Subject"
          required
          onChange={(e) => {
            setSubject(e?.target.value);
          }}
        />
      </div>
      <div className="col-12 my-2">
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Message"
          required
          onChange={(e) => {
            setBody(e?.target.value);
          }}
        ></textarea>
      </div>
      <div className="col-12 d-flex justify-content-center my-5">
        <button
          type="submit"
          className="btn app-primary btn-lg"
          disabled={
            !([firstName, email, subject, body].filter(Boolean).length === 4) ||
            isProgress
          }
        >
          <div className="d-flex align-items-center">
            <span className={isProgress ? "pe-3" : ""}>Send Message</span>{" "}
            {isProgress && (
              <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
        </button>
      </div>
    </form>
  );
};
export const ContactUs = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 px-md-0">
          <div className="row my-5">
            <div className="col-12 col-md-6 order-md-2">
              <img
                src={conatcatUsImage}
                className="contact-us-img w-100"
                alt="contact-us"
              />
            </div>
            <div className="col-12 col-md-4 order-md-1 px-md-0">
              <div className="row align-items-start my-3">
                <div className="col-1">
                  {" "}
                  <i className="bi bi-geo-alt-fill"></i>
                </div>
                <div className="col-11">
                  <address className="mb-0">
                    No, 203, Nethaji Appartment, Navodhya Balaji Rainbow Villas,
                    Tadepalli - Guntur, Vijyawada - Guntur -522501
                  </address>
                </div>
              </div>
              <p className="my-4"><b>Please fill the details to send an email</b></p>

              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
