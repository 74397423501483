import ServicesStep from "../Services-Step/ServicesStep";
import { Link } from "react-router-dom";

export default function ApplicationAdm() {
  const services = [
    {
      step: "STEP1",
      head: "Find the Right Program and University",
      text: "We provide you a list of best universities and programs for your profile that meet your unique background and interests.",
    },
    {
      step: "STEP2",
      head: "Submit Your Application",
      text: "Once you done with your selection, complete your online profile, pay the application fee, and submit the required documents. We will review your application and ensure you’re not missing any information to secure an admission.",
    },
    {
      step: "STEP3",
      head: "Get Your Letter of Acceptance",
      text: "Your application is submitted and reviewed by the University. If accepted, you will quickly receive your acceptance letter.",
    },
    {
      step: "STEP4",
      head: "Start the Visa Process",
      text: "Our team of experts provides you with guidance and support during the visa application process and beyond, to give you the best chance of success.",
    },
    {
      step: "STEP5",
      head: "Book Your Plane Ticket and Go!",
      text: "Once you’ve got your acceptance and visa, book your plane ticket and begin your educational journey abroad! Don’t forget to arrange your accommodations.",
    },
  ];

  document.title = 'Services | Appliction and admisions';

  return (
    <div className="container">
      <section className="row justify-content-center">
        {services.map((x) => {
          return (
            <div className="col-10 py-2">
              <ServicesStep p={x}></ServicesStep>
            </div>
          );
        })}
      </section>
      <div className="text-center py-5">
        <Link className="btn contact-btn px-4 py-3" to="/contact">
          Contact Us
        </Link>
      </div>
    </div>
  );
}
