import { Outlet } from "react-router";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import { SecondaryNav } from "../SecondaryNav/SecondaryNav";

export const SecndaryPageLayout = (props) => {
  return (
    <>
      <Navbar invert />
      <SecondaryNav {...props}/>
      <main>
        {props?.children && props.children}
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
