import {SecndaryPageLayout} from '../../Components/SecndaryPageLayout/SecndaryPageLayout';
import About from './About/About';
import Journey from './Journey/Journey';
import VVhistory from './VV History/VVhistory';

export const AboutMe = () => {
  document.title = 'About US';

  return (
    <SecndaryPageLayout>
        <About></About>
        <Journey></Journey>
        <VVhistory></VVhistory>
    </SecndaryPageLayout>
  );
};
