import "./Vision.css";

export const Vision = () => {
  return (
    <div className="container-fluid vison-host px-3">
      <div className="row justify-content-center">
        <div className="col-9 col-md-10">
          <div className="row vision-item-container">
            <div className="col-12 col-lg-4 p-3 text-center">
              <figure>
                <img src="/assets/home/CashFlow-t.webp" alt="test" />
                <figcaption className="mt-2">WORLD CLASS EDUCATION</figcaption>
              </figure>
              <small>
                High Standard and Education for rapidly growing technological
                advancements
              </small>
            </div>

            <div className="col-12 col-lg-4 p-3 text-center">
              <figure>
                <img src="/assets/home/MarketForcast-t.webp" alt="test" />
                <figcaption  className="mt-2">GREATE OPPORTUNITIES</figcaption>
              </figure>
              <small>
                Ample of opportunities in the industry to explore diverse fields
              </small>
            </div>

            <div className="col-12 col-lg-4 p-3 text-center">
              <figure>
                <img src="/assets/home/FundsRaising-t.webp" alt="test" />
                <figcaption  className="mt-2">HIGH STANDARD OF LIVING</figcaption>
              </figure>
              <small>
                Greate culture experience with high standard of living in one of
                the best among the top most countries
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
