import "./ServicesStep.css";
export default function ServicesStep(props) {
  return (
    <section className="container-fluid">
      <div className="row align-items-center servicestep-container">
        <div className="col-12 col-md-2 py-4 py-md-0">
          <h5 className="fw-bold m-0 text-center">{props.p.step}</h5>
        </div>
        <div className="col-12 col-md-10 py-2 servicestep-text d-flex flex-column justify-content-center">
          <div className="small fw-bold py-2 text-center text-md-start">
            {props.p.head}
          </div>
          <p className="small col-9 text-center text-md-start w-100 text-justify">
            {props.p.text}
          </p>
        </div>
      </div>
    </section>
  );
}
