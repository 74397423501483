import styles from "./Navbar.module.css";
// import logo from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import { useResolvedPath, useMatch } from "react-router";
import { Logo } from "../Logo/Logo";
const navs = [
  ["Home", "/"],
  ["Services", "/services"],
  ["About", "/about"],
  ["Contact", "/contact"],
].map((x) => ({ title: x[0], path: x[1] }));

const NavItem = ({ path, title }) => {
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: false });
  // console.log({ path: resolved.pathname, end: m }, match)

  return (
    <Link
      className={`${styles[`nav-item`]} ${
        match && resolved.pathname !== "/" ? styles["active"] : ""
      }`}
      to={path}
    >
      {title}
    </Link>
  );
};

export const Navbar = ({ invert }) => {
  return (
    <nav
      className={`container-fluid ${styles["navbar"]} ${
        invert ? styles["invert"] : ""
      } position-absolute`}
    >
      <div className="row align-items-center">
        <div className="col-6">
          <div className="d-flex">
            {/* <img
              src={logo}
              className={`${styles["logo"]}`}
              alt="logo"
            /> */}
            <Link to={"/"}>
              <Logo />
            </Link>
          </div>
        </div>
        <div className="col-md-6 d-none d-lg-flex justify-content-end">
          {navs.map((x, i) => (
            <NavItem {...x} key={i} />
          ))}
        </div>
      </div>
    </nav>
  );
};
