import './Journey.css';
import '../../AboutMe/Aboutme.css'

function Journey() {
    return (
        <div className="text-white journey-container">
            <div className="container-fluid">
                <div className="container">
                    <section className="row">
                        <div className="col-12 col-md-9 py-5">
                            <h1 className="h1 fw-bold py-3">
                                The Journey
                            </h1>
                            <p className="small text-justify">
                                I came to Germany in the summer of 2015, I started my Masters program in the field of Data Science.
                                I spent one year getting know the new way of doing things and the organizational changes of the studies along with German Culture,
                                By travelling many cities and countries in Europe,I realized that the thought of spending the rest of my life sitting in front of a
                                computer screen and calculating bending moments and shear forces in building isn’t something that I would only love to do.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Journey;