import "./App.css";
import { Home } from "./Pages/Home/Home";
import { AboutMe } from "./Pages/AboutMe/AboutMe";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Servicespage from "./Pages/Services/Services";
import VisaTravel from "./Pages/Services/Visa&Travel/Visa&Travel";
import BlockedAccountAndInsurance from "./Pages/Services/BlockedAccountAndInsurance/BlockedAccountAndInsurance";
import ApplicationAndAdmission from "./Pages/Services/ApplicationAndAddmission/ApplicationAndAdmission";
import { SecndaryPageLayout } from "./Components/SecndaryPageLayout/SecndaryPageLayout";
import { ContactUs } from "./Pages/ContactUs/ContactUs";
import { ProfileEvaluation } from "./Pages/Services/ProfileEvaluation/ProfileEvaluation";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutMe />} />
          <Route path="/services" element={<Servicespage />}>
            <Route path="visa" element={<VisaTravel />} />
            <Route
              path="blocked-account"
              element={<BlockedAccountAndInsurance />}
            />
            <Route
              path="application-and-admission"
              element={<ApplicationAndAdmission />}
            />
            <Route index element={<ProfileEvaluation />} />
            <Route path="*" element={<BlockedAccountAndInsurance />} />
          </Route>
          <Route
            path="/contact"
            element={<SecndaryPageLayout title={"Get in Touch"} />}
          >
            <Route index element={<ContactUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
