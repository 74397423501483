import { Link } from "react-router-dom";
import styles from "./ContactUs.module.css";

export const ContactUsBanner = () => {
  return (
    <section className={`container-fluid ${styles['contactus-banner']}`}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 text-center">
          <blockquote className="h1"> Your Vision.Our Passion.</blockquote>
          <p>Educational consulting expertise to buid your future.</p>
          <Link className={`btn app-primary btn-lg`} to={'/contact'}>Contact</Link>
        </div>
      </div>
    </section>
  );
};
